import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`In the north-west of Slovenia the Soca river rises in the high mountains
behind Bovec. The crystal clear water, cataracts and canyons are world
famous for white water kayaking.`}</p>
    <br />
    <p>{`In the beautiful valley you also find many
great and wild singletracks. Most descents you have to earn yourself but you
will find guiding and shuttle services.`}</p>
    <br />
    <p>{`Since 2017 the Kanin mountain resort
reopened and also takes up mountain bikes. Best time of the year is from June
to September.`}</p>
    <TrailguidePlugin content="lat=46.3368&lng=13.5526&coverage=10" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=13,46.3368,13.5526"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Bovec Enduro Loop`}</h4>
    <p>{`Lets start with a cool loop, connecting several nice descents.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1720"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/bovec1.jpg" mdxType="Image" />
    <br />
    <p>{`Take a breath and enjoy the beautiful landscape of the valley.`}</p>
    <Image src="destinations/bovec7.jpg" mdxType="Image" />
    <br />
    <h4>{`Stol North`}</h4>
    <p>{`The Stol mountain is one of the classics. Usually climbed from the south side
on a long military road, there is a nice trail going down on the other side.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1721"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/bovec5.jpg" mdxType="Image" />
    <br />
    <h4>{`Shuttles`}</h4>
    <p>{`You can even get some uphill support.`}</p>
    <Image src="destinations/bovec6.jpg" mdxType="Image" />
    <br />
    <h4>{`Kanin Singletrail`}</h4>
    <p>{`Or you take the gondola of the recently reopened Kanin resort.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1748"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/bovec9.jpg" mdxType="Image" />
    <br />
    <h4>{`Krnica adventure`}</h4>
    <p>{`Higher up you can still find some snow in summer time.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1747"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/bovec4.jpg" mdxType="Image" />
    <br />
    <p>{`Dive into a lonesome valley.`}</p>
    <Image src="destinations/bovec3.jpg" mdxType="Image" />
    <br />
    <p>{`Looks easy but is more challenging than it looks.`}</p>
    <Image src="destinations/bovec2.jpg" mdxType="Image" />
    <br />
    <h4>{`Matajur`}</h4>
    <p>{`The more you go down the valley, the flowier the trails become.
This is one of our favourite trails.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1749"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/bovec8.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      